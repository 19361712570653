// Import
import firebase from '../config/FirebaseConfig'
import ForeplayAPI from '@/api/foreplayServer'

// Setup
const db = firebase.firestore()

// Get All Advertisements By User
export const getAllByUser = async () => {
  const user = firebase.auth().currentUser

  try {
    const ads = await db.collection('ads').where('created_by', '==', user.uid).get()

    return ads.docs?.map(doc => {
      return {
        id: doc.id,
        ...doc.data() || []
      }
    })
  } catch (e) {
    console.log(e)
  }
}

// Get An Advertisement By ad_id
export const getByID = async (id) => {
  const ads = await db.collection('ads').where('ad_id', '==', Number(id)).get()
  return ads.docs[0].data()
}

export const getUniqueAdById = async (id) => {
  const ad = await db.collection('unique-ads').doc(id).get()
  return {
    id: ad.id,
    ...ad.data()
  }
}

// Get and Advertisment by its FirebaseId
export const get = async (id) => {
  const ad = await db.collection('ads').doc(id).get()
  return {
    id,
    ...ad.data()
  }
}

// Get All Advertisements By Board
export const getAllByBoard = async (boardID) => {
  try {
    const ads = await db.collection('ads').where('board_ids', 'array-contains', boardID).get()

    return ads.docs?.map(doc => {
      return {
        id: doc.id,
        ...doc.data() || []
      }
    })
  } catch (e) {
    console.log(e)
  }
}

const create = async (payload) => {
  return db.collection('ads').add(payload)
}

const save = async (payload, teamId, isSpyderView = false) => {
  const user = firebase.auth().currentUser

  const uniqueAdId = payload.id // Only used in case of spyder ad

  // Cleanup the advertisement object
  delete payload.crated_at
  delete payload.created_at
  delete payload.id
  delete payload.queryId
  delete payload.objectID
  delete payload.searchIndex
  delete payload.teamId
  delete payload.tags

  // Add created by
  payload.created_by = user.uid

  // For some reason, some discovery ads don't have an associated brand. We add it here
  let brandSnapshot
  if (!payload.brandId) {
    const brandSnapshotDocs = await db
      .collection('brands')
      .where('name', '==', payload.name)
      .get()
    brandSnapshot = brandSnapshotDocs.docs[0]
  } else {
    brandSnapshot = await db
      .collection('brands')
      .doc(payload.brandId)
      .get()
  }

  if (brandSnapshot) {
    // SCRAPER NOT ADDING BRAND ID TO THE NEW ADS
    const brand = {
      ...brandSnapshot.data(),
      id: brandSnapshot.id
    }

    let userBrandSnapshot
    // Add TeamId if there is one
    if (teamId) {
      payload.teamId = teamId
      userBrandSnapshot = await db
        .collection('user-brands')
        .where('name', '==', payload.name)
        .where('teamId', '==', teamId)
        .get()
    } else {
      userBrandSnapshot = await db
        .collection('user-brands')
        .where('name', '==', payload.name)
        .where('createdBy', '==', user.uid)
        .get()
    }

    // Create the searchable brand
    let userBrandId = userBrandSnapshot.empty ? null : userBrandSnapshot.docs[0].id
    if (!userBrandSnapshot.docs.length) {
      const userBrandPayload = {
        name: brand.name,
        avatar: brand.avatar,
        brandId: brand.id,
        createdBy: user.uid,
      }
      if (teamId) userBrandPayload.teamId = teamId

      const { id } = await db.collection('user-brands').add(userBrandPayload)
      userBrandId = id

      // Add visible_by for the search
      const visibleBy = [user.uid]
      if (teamId) visibleBy.push(`group/${teamId}`)

      // Add the user brand to the search collection
      await db.collection('search').doc().set({
        ...userBrandPayload,
        visible_by: visibleBy,
        id,
        type: 'brand'
      })
    }

    // Create the advertisement
    payload.brandId = brand.id
    payload.userBrandId = userBrandId
  }

  const { id } = await db.collection('ads').add(payload)
  if (isSpyderView && payload.spyderAd && payload.hideFromFeed) {
    await ForeplayAPI.Ads.setSpyderAdDiscoverable(uniqueAdId)
  }
  return id
}

// Update Ad
const update = async (adID, payload) => {
  return await db
    .collection('ads')
    .doc(adID)
    .update(payload)
}

export default {
  getAllByBoard,
  getByID,
  getUniqueAdById,
  get,
  getAllByUser,
  create,
  update,
  save
}
