<template>
  <div class="p-10">
    <!-- Test animated number counter -->
    <div class="bg-white mb-10 p-10 rounded-lg">
      <form class="flex items-center gap-1.5 mb-8" @submit.prevent="() => {animatedCounterValue = parseInt(inputCounterValue)}">
        <input v-model="inputCounterValue" class="px-2 py-0.5 rounded-md border border-border-normal focus:outline-none">
        <button type="submit" class="px-2 py-0.5 rounded-md border border-border-normal">
          Set
        </button>
      </form>
      <BaseAnimatedCounter :value="animatedCounterValue" class="text-text-muted text-lg" />
    </div>
    <!-- Test notifications -->
    <div class="bg-white mb-10 p-10 rounded-lg">
      <div class="text-lg font-semibold mb-4">
        Response Alert Testing Board
      </div>
      <div class="mb-2">
        Success Alerts
      </div>
      <div class="flex gap-2 items-center mb-4">
        <button class="py-2 px-3 rounded-md bg-secondary-green-100 text-white"
        @click="$showAlert({ type: 'success', message: 'Operation succeeded'})">
          Show Success 1
        </button>
        <button class="py-2 px-3 rounded-md bg-secondary-green-100 text-white"
        @click="$showAlert({ type: 'success', message: 'Operation performed successfully'})">
          Show Success 2
        </button>
        <button class="py-2 px-3 rounded-md bg-secondary-green-100 text-white"
        @click="$showAlert({ type: 'success', message: 'Success!'})">
          Show Success 3
        </button>
      </div>
      <div class="mb-2">
        Error Alerts
      </div>
      <div class="flex gap-2 items-center mb-4">
        <button class="py-2 px-3 rounded-md bg-primary-red-100 text-white"
        @click="$showAlert({ type: 'error', message: 'Operation failed'})">
          Show Error 1
        </button>
        <button class="py-2 px-3 rounded-md bg-primary-red-100 text-white"
        @click="$showAlert({ type: 'error', message: 'Error performing operation'})">
          Show Error 2
        </button>
        <button class="py-2 px-3 rounded-md bg-primary-red-100 text-white"
        @click="$showAlert({ type: 'error', message: 'Failed to update. See console for details'})">
          Show Error 3
        </button>
      </div>
      <div class="mb-2">
        Warning Alerts
      </div>
      <div class="flex gap-2 items-center mb-4">
        <button class="py-2 px-3 rounded-md bg-secondary-yellow-100 text-white"
        @click="$showAlert({ type: 'warning', message: 'Unrecommended operation'})">
          Show Warning 1
        </button>
        <button class="py-2 px-3 rounded-md bg-secondary-yellow-100 text-white"
        @click="$showAlert({ type: 'warning', message: 'Operation failed successfully?'})">
          Show Warning 2
        </button>
        <button class="py-2 px-3 rounded-md bg-secondary-yellow-100 text-white"
        @click="$showAlert({ type: 'warning', message: 'Feature will be deprecated in later release'})">
          Show Warning 3
        </button>
      </div>
      <div class="mb-2">
        Info Alerts
      </div>
      <div class="flex gap-2 items-center mb-4">
        <button class="py-2 px-3 rounded-md bg-primary-blue-100 text-white"
        @click="$showAlert({ type: 'info', message: 'Extra information'})">
          Show Info 1
        </button>
        <button class="py-2 px-3 rounded-md bg-primary-blue-100 text-white"
        @click="$showAlert({ type: 'info', message: 'See dashboard for more information'})">
          Show Info 2
        </button>
        <button class="py-2 px-3 rounded-md bg-primary-blue-100 text-white"
        @click="$showAlert({ type: 'info', message: 'You can add more items in by navigating to SwipeFile'})">
          Show Info 3
        </button>
      </div>
    </div>
    <!-- Settings -->
    <div class="bg-white mb-10 p-10 rounded-lg">
      <div class="text-lg font-semibold mb-4">
        Settings
      </div>

      <div class="text-md mb-2">
        Set Theme
      </div>
      <div class="flex">
        <BaseButton
          :primary="getTheme === 'blue'"
          :outlined="getTheme !== 'blue'"
          @click="setTheme('blue')"
        >
          Blue
        </BaseButton>
        <BaseButton
          :primary="getTheme === 'purple'"
          :outlined="getTheme !== 'purple'"
          class="mx-2"
          @click="setTheme('purple')"
        >
          Purple
        </BaseButton>
        <BaseButton
          :primary="getTheme === 'green'"
          :outlined="getTheme !== 'green'"
          @click="setTheme('green')"
        >
          Green
        </BaseButton>
      </div>
    </div>

    <!-- Buttons -->
    <div class="bg-white p-10 rounded-lg">
      <div class="text-lg font-semibold mb-4">
        Buttons
      </div>

      <!-- Primary -->
      <div class="flex items-end gap-8">
        <BaseButton primary>
          Primary
        </BaseButton>

        <BaseButton
          primary
          large
        >
          Large
        </BaseButton>
      </div>

      <br>

      <!-- Secondary -->
      <div class="flex items-end gap-8">
        <BaseButton outlined>
          Secondary
        </BaseButton>
        <BaseButton
          outlined
          large
        >
          Large
        </BaseButton>
      </div>

      <br>

      <!-- Danger Button -->
      <div class="flex items-end gap-8">
        <BaseButton
          danger
        >
          Danger
        </BaseButton>
        <BaseButton
          danger
          large
        >
          Danger
        </BaseButton>
      </div>

      <br>

      <!-- Destroy Button -->
      <div class="flex items-end gap-8">
        <BaseButton
          destroy
        >
          Destroy
        </BaseButton>
        <BaseButton
          destroy
          large
        >
          Destroy
        </BaseButton>
      </div>

      <br>

      <!-- Disabled -->
      <div class="flex items-end gap-8">
        <BaseButton
          primary
          disabled
        >
          Disabled
        </BaseButton>
        <BaseButton
          primary
          disabled
          large
        >
          Disabled
        </BaseButton>
      </div>

      <br>

      <!-- Text -->
      <div class="flex items-end gap-8">
        <BaseButton
          text
        >
          Text
        </BaseButton>
        <BaseButton
          text
          large
        >
          Text
        </BaseButton>
      </div>
    </div>

    <!-- View Toggle -->
    <div class="bg-white p-10 rounded-lg mt-10">
      <div class="text-lg font-semibold mb-4">
        View Toggle
      </div>

      <!-- Double Toggle -->
      <BaseViewToggle
        :views="['View 1', 'View 2']"
        :value="viewToggle1Value"
        @input="(value) => viewToggle1Value = value"
      />
    </div>

    <!-- Inputs -->
    <div class="bg-white p-10 rounded-lg mt-10">
      <div class="text-lg font-semibold mb-4">
        Inputs
      </div>

      <!-- Normal with Label -->
      <div class="my-4">
        <BaseInputV2
          label="Label"
          placeholder="Placeholder"
        />
      </div>

      <!-- Icon -->
      <div class="my-4">
        <BaseInputV2
          label="Label"
          placeholder="Placeholder"
          icon
        >
          <template slot="icon">
            <img src="../../assets/icons/calendar.svg">
          </template>
        </BaseInputV2>
      </div>
      <!-- Hint -->
      <div class="my-4">
        <BaseInputV2
          label="Label"
          placeholder="Placeholder"
          hint="This is a hint text to help the user."
        />
      </div>

      <!-- Errored -->
      <div class="my-4">
        <BaseInputV2
          label="Label"
          placeholder="Placeholder"
          hint="This is a hint text to help the user."
          errored
          error-message="This is an error message."
        />
      </div>

      <!-- Disabled -->
      <div class="my-4">
        <BaseInputV2
          label="Label"
          placeholder="Placeholder"
          hint="This is a hint text to help the user."
          disabled
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'BaseComponentView',
  data () {
    return {
      viewToggle1Value: 'View 1',
      animatedCounterValue: 1350334,
      inputCounterValue: "1350334"
    }
  },
  computed: {
    ...mapGetters('MiscModule', ['getTheme'])
  },
  methods: {
    ...mapMutations('MiscModule', ['SET_THEME']),
    setTheme (theme) {
      this.SET_THEME(theme)
    }
  }
}
</script>
