import Ads from './AdsRequests'
import Brands from './BrandsRequests'
import Screenshots from './ScreenshotsRequests'
import Users from './UsersRequests'

export default {
  Ads,
  Brands,
  Screenshots,
  Users
}
